import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router/index";
import configPlugin from './plugins/config';
fetch('/config.json?timestamp=' + new Date().getTime())
    .then(response => response.json())
    .then(config => {
        // 删除加载状态的 Vue 实例
        // 创建主应用实例并注入配置
        const app = createApp(App);
        app.use(configPlugin, config);
        app.use(router);
        app.mount('#app');
    })
    .catch(error => {
        console.error('Failed to load config:', error);
    });
createApp(App).mount('#app')
