<template>
  <div style="display: flex; justify-content: center">
    <div style="position: relative; max-width: 520px">
      <img class="bag_img" src="@/assets/HsImag/di.png" alt="">
      <div class="conten_box">
        <div class="header_box center_view">
          <img src="@/assets/HsImag/logo.png" alt="">
        </div>
        <div class="center_view">
          <img class="hander_img" src="@/assets/HsImag/icon.png" alt="">
        </div>
        <div class="btn_view center_view">
          <img id="downloadButton" width="50%" src="@/assets/HsImag/anzhuo.png" alt="">
          <div id="qrCodeElement"></div>
          <img class="kefu_btn" @click="supperGame" width="15%" src="@/assets/HsImag/kefu_.png" alt="">
        </div>
        <div class="text_1 center_view">
          <img width="95%" src="@/assets/HsImag/1.png" alt="">
        </div>
        <div class="text_1 center_view">
          <img width="95%" src="@/assets/HsImag/2.png" alt="">
        </div>
        <div class="text_1 center_view">
          <img width="95%" src="@/assets/HsImag/50000.png" alt="">
        </div>
        <div class="bomm_img center_view">
          <img width="95%" src="@/assets/HsImag/youxi.png" alt="">
        </div>
        <div class="bomm2_img center_view">
          <img width="95%" src="@/assets/HsImag/shouji.png" alt="">
        </div>
      </div>
    </div>

  </div>

</template>
<script setup>
import service from "@/plugins/API";

import {getCurrentInstance, onMounted} from 'vue';
const {appContext} = getCurrentInstance();

const formatTime = (ms) => {
  // 转换为秒
  let seconds = ms / 1000;
  // 获取分钟数
  const minutes = Math.floor(seconds / 60);
  // 获取剩余的秒数
  seconds = (seconds % 60).toFixed(2);
  return `${minutes} m ${seconds} s`;
}
onMounted(()=>{
  let lodTime = new FormData()
  lodTime.append("eventID", '213')
  lodTime.append("modelID", '25')
  lodTime.append("param1", formatTime(performance.now()))
  service({
    url: '/info/event/callback',
    method: 'post',
    data: lodTime
  }).then(res => console.log(res)).catch(err => console.log(err))
  let prson = new FormData()
  prson.append("eventID", '212')
  prson.append("modelID", '25')
  prson.append("param1", '1')
  service({
    url: '/info/event/callback',
    method: 'post',
    data: prson
  }).then(res => console.log(res)).catch(err => console.log(err))
  let lowd = new FormData()
  lowd.append("eventID", '214')
  lowd.append("modelID", '25')
  lowd.append("param1", formatTime(performance.now()))
  service({
    url: '/info/event/callback',
    method: 'post',
    data: lowd
  }).then(res => console.log(res)).catch(err => console.log(err))
})
const data = window.OpenInstall.parseUrlParams();///openinstall.js中提供的api，解析当前网页url中的查询参数并对data进行赋值
  new window.OpenInstall({
    appKey: "qnb0wm",//appKey为openinstall为应用分配的唯一id（必须传入）
    channelCode:"0905qnb0wm", // 为页面添加分组
    onready: function () {//初始化成功回调方法。当初始化完成后，会自动进入
      this.schemeWakeup();//尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
      const m = this,
      button = document.getElementById("downloadButton");//为button对象绑定对应id的元素
      // eslint-disable-next-line no-undef
      button.onclick = function () {//对应button的点击事件
        let fromData = new FormData()
        fromData.append("eventID", '215')
        fromData.append("modelID", '25')
        fromData.append("param1", '用户点击了下载')
        service({
          url: '/info/event/callback',
          method: 'post',
          data: fromData
        }).then(res => console.log(res)).catch(err => console.log(err))
        m.wakeupOrInstall();//此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
        return false;
      }
    }
  }, data);

const supperGame = () => {
  // 跳转客服
  const supportUrl = appContext.config.globalProperties.$config.serVer;
  window.open(supportUrl);
};
</script>

<style scoped>
.bag_img {
  background: url("@/assets/HsImag/di.png");
  width: 100%;
  height: 100%;
}

.conten_box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header_box img {
  width: 100%;
  height: 100%;
}
#downloadButton{
  z-index: 9999;
}
.center_view {
  display: flex;
  justify-content: center;
}

.hander_img {
  width: 35%;
}

.kefu_btn {
  right: 2%;
  position: absolute;
  top: -55%
}

.btn_view {
  position: relative;
}

.bomm_img {
  z-index: 10;
  position: sticky;
}

.bomm2_img {
  position: absolute;
  bottom: -24%;
  z-index: 1;
}
</style>
